import moment from 'moment'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import omit from 'lodash/omit'
import store from 'store2'
import { FormattedMessage } from 'react-intl'
import messages from './messages'
import {
  APP_LANGUAGE,
  createUrl as createTranslatedUrl,
} from './localized-urls'
import each from 'lodash/each'
import pickBy from 'lodash/pickBy'
import { toast } from 'react-toastify'
import get from 'lodash/get'
import API from 'api'
import { isMainLand } from './config'

export function getTranslations(language, renderApp) {
  window
    .fetch(
      window.staticPath +
        `travel-app/dist/translations/travel-${language}.json`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    )
    .then(function(response) {
      response
        .json()
        .then((msg) => {
          renderApp(msg[`travel-${language}`])
        })
        .catch(() => {
          renderApp()
        })
    })
    .catch(() => {
      renderApp()
    })
}

export function isLoggedIn() {
  return getIdToken()
}

export function getToken() {
  return getIdToken()
}

export function removeToken() {
  removeCognitoCookies()
  return store.remove('token')
}

export function getLanguage() {
  let langPrefix = window.location.pathname.match(
    /^\/(de|fr|it|es|ko|nl|ja|ach|zh-hant|zh-hans|)\/?/,
  )
  let lang = 'en'

  if(langPrefix && langPrefix[0] !== '/') {
    lang = langPrefix[0].replace(/\//g, '')
  }

  return lang
}

function getCognitoCookieName() {
  const aud = process.env.COGNITO_ID
  const sub = getCookie(`CognitoIdentityServiceProvider.${aud}.LastAuthUser`)

  return `CognitoIdentityServiceProvider.${aud}.${sub}`
}

export function getRefreshToken() {
  return getCookie(`${getCognitoCookieName()}.refreshToken`)
}

export function getIdToken() {
  return getCookie(`${getCognitoCookieName()}.idToken`)
}

export function setRefreshToken({ token, expires }) {
  setCookie(`${getCognitoCookieName()}.refreshToken`, token, {
    expires,
    domain: '.' + window.location.hostname.split('.').splice(1).join('.'),
    path: '/',
  })
}

export function setIdToken({ token, expires }) {
  setCookie(`${getCognitoCookieName()}.idToken`, token, {
    expires,
    domain: '.' + window.location.hostname.split('.').splice(1).join('.'),
    path: '/',
  })
}

export function removeCognitoCookies() {
  deleteCookie(`${getCognitoCookieName()}.refreshToken`)
  deleteCookie(`${getCognitoCookieName()}.idToken`)
}

export function deleteCookie(name) {
  document.cookie =
    name +
    '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=' +
    '.' +
    window.location.hostname.split('.').splice(1).join('.')

  return true
}

export function serializeData(params, keys = [], isArray = false) {
  const cleanedParams = pickBy(params, (val) => {
    if(Array.isArray(val)) {
      return val.length
    } else if(typeof val === 'undefined') {
      return false
    }
    return true
  })
  const p = Object.keys(cleanedParams)
    .map((key) => {
      let val = params[key]

      if(
        Object.prototype.toString.call(val) === '[object Object]' ||
        Array.isArray(val)
      ) {
        if(Array.isArray(params)) {
          keys.push('')
        } else {
          keys.push(key)
        }
        return serializeData(val, keys, Array.isArray(val))
      } else {
        let tKey = key

        if(keys.length > 0) {
          const tKeys = isArray ? keys : [...keys, key]
          tKey = tKeys.reduce((str, k) => {
            return str === '' ? k : `${str}[${k}]`
          }, '')
        }
        if(typeof val !== 'undefined') {
          try {
            return `${tKey}=${val}`
          } catch (e) {
            console.error(e)
          }
        }
      }
    })
    .join('&')
  keys.pop()
  return p
}

export function scrollTo(element, to, duration = 300) {
  if(duration <= 0) {
    return
  }
  let difference = to - element.scrollTop
  let perTick = (difference / duration) * 10

  setTimeout(function() {
    element.scrollTop = element.scrollTop + perTick
    if(element.scrollTop === to) {
      return
    }
    scrollTo(element, to, duration - 10)
  }, 10)
}

export function getRelativePos(elm) {
  const coords = getOffset(elm)
  const parentCoords = getOffset(elm.parentElement)

  return {
    top: coords.top - parentCoords.top,
    left: coords.left - parentCoords.left,
  }
}

export function calculateDiscountPercent(first, second) {
  return Math.round(100 - (first / second) * 100)
}

export function parseQuery(url = document.location.search) {
  const qs = window.location.search.replace('?', '')
  const items = qs.split('&')

  if(!qs) {
    return {}
  }

  // Consider using   reduce to create the data mapping
  return items.reduce((data, item) => {
    const splittedItem = item.split('=')
    const key = splittedItem[0]

    if(!key) {
      return data
    }

    const value =
      splittedItem.length > 2
        ? window.decodeURIComponent(splittedItem.slice(1).join('='))
        : splittedItem[1]
    // Sometimes a query string can have multiple values
    // for the same key, so to factor that case in, you
    // could collect an array of values for the same key
    if(data[key] !== undefined) {
      // If the value for this key was not previously an
      // array, update it
      if(!Array.isArray(data[key])) {
        data[key] = [decodeURIComponent(data[key])]
      }
      data[key].push(decodeURIComponent(value))
    } else {
      data[key] = decodeURIComponent(value)
    }

    return data
  }, {})
}

export function setCookie(name, value, options = {}, isWidget) {
  var expires = options.expires

  if(typeof expires === 'number' && expires) {
    var d = new Date()
    d.setTime(d.getTime() + expires * 1000)
    expires = options.expires = d
  }

  if(expires && expires.toUTCString) {
    options.expires = expires.toUTCString()
  }

  value = encodeURIComponent(value)

  var updatedCookie = `${name}=${value}`

  for(var propName in options) {
    updatedCookie += `;${propName}`
    var propValue = options[propName]
    if(propValue !== true) {
      updatedCookie += `=${propValue}`
    }
  }

  if(isWidget) {
    updatedCookie += 'cross-site-cookie2=noneCookie; SameSite=None; Secure' // secure cross-site cookies policy
  }

  document.cookie = updatedCookie
}

export function getCookie(name) {
  let a = `; ${document.cookie}`.match(`;\\s*${name}=([^;]+)`)
  return a ? a[1] : ''
}

export function animate(draw, duration, callback) {
  var start = window.performance.now()

  window.requestAnimationFrame(function animate(time) {
    // определить, сколько прошло времени с начала анимации
    var timePassed = time - start

    // возможно небольшое превышение времени, в этом случае зафиксировать конец
    if(timePassed > duration) {
      timePassed = duration
    }

    // нарисовать состояние анимации в момент timePassed
    draw(timePassed)

    // если время анимации не закончилось - запланировать ещё кадр
    if(timePassed < duration) {
      window.requestAnimationFrame(animate)
    } else {
      if(callback) {
        callback()
      }
    }
  })
}

export function getOffset(el) {
  if(!el) {
    return false
  }
  const bodyRect = document.body.getBoundingClientRect()
  const elemRect = el.getBoundingClientRect()
  const offsetLeft = elemRect.left - bodyRect.left
  const offsetTop = elemRect.top - bodyRect.top

  return { top: offsetTop, left: offsetLeft }
}

export function setStyle(element, propertyObject) {
  for(var property in propertyObject) {
    element.style[property] = propertyObject[property]
  }
}

export function generateQueryForTrip(trip, isWidget) {
  let query = '?'
  let queryParams = parseQuery()

  if(isWidget) {
    query = `?utm_campaign=ww-all-all-di-affiliates&utm_medium=widget&utm_content=${
      queryParams.popup ? 'search_modal' : 'search_iframe'
    }&`
  }

  if(queryParams.duration) {
    if(Array.isArray(queryParams.duration)) {
      queryParams.duration.map(function(val) {
        query += `duration=${val}&`
      })
    } else {
      query += `duration=${queryParams.duration}&`
    }
  }

  if(queryParams.harbours) {
    if(Array.isArray(queryParams.harbours)) {
      queryParams.harbours.map(function(val) {
        query += `harbours=${val}&`
      })
    } else {
      query += `harbours=${queryParams.harbours}&`
    }
  }

  if(queryParams.aid) {
    query += `utm_source=affiliate_${queryParams.aid}&aid=${queryParams.aid}&`

    if(queryParams.cid) {
      query += `cid=${queryParams.cid}&`
    }
  }

  if(trip) {
    query += `trip_id=${trip.id}&trip_date=${moment(trip.startDate).format(
      'YYYY-MM-DD',
    )}`
  }

  if(query === '?') {
    query = ''
  }

  return query
}

export function generateQueryForWidget(url = '') {
  if(!window.isWidget) {
    return url
  }

  const params = parseQuery()

  let query = `${
    url.indexOf('?') === -1 ? '?' : '&'
  }utm_campaign=ww-all-all-di-affiliates&utm_medium=widget&utm_content=${
    params.popup ? 'search_modal' : 'search_iframe'
  }&widget=true`

  if(params.aid) {
    query += `&utm_source=affiliate_${params.aid}&aid=${params.aid}`

    if(params.cid) {
      query += `&cid=${params.cid}`
    }
  }
  if(params.partner) {
    query += `&partner=${params.partner}`
  }

  return url + query
}

export function isValidEmail(email) {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)
}

export function isValidURL(url) {
  return /^((http:|https:)?(\/\/)?(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|^www\.[^\s]+\.[^\s]{2,})$/.test(
    url,
  )
}

export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  )
}

export function isIOsSafari() {
  return (
    navigator.userAgent.match(/iPhone|iPad|iPod/i) &&
    navigator.userAgent.match(/Version\/[\d\.]+.*Safari/)
  )
}

export function isIOS() {
  return navigator.userAgent.match(/iPhone|iPad|iPod/i)
}

export const target = isMobile() || window?.isWidget ? '_self' : '_blank'

export function getTextFromInfo(id, category, option) {
  if(!window.info) {
    return ''
  }

  let element = window.info[category][option]
  let index = findIndex(element, function(val) {
    return val[0] === id || val.value === id
  })

  if(index !== -1) {
    return element[index][1] || element[index].title
  }
}

export function getDefaultMeasurementUnits() {
  /* eslint-disable camelcase */
  const {
    shoe_size_unit,
    height_unit,
    weight_unit,
  } = window.info.defaultMeasurements
  return {
    shoeSizeUnit: shoe_size_unit,
    heightUnit: height_unit,
    weightUnit: weight_unit,
  }
}

export function isStaff() {
  const user = store.get('user')

  return user && user.isStaff
}

export function isAffiliate() {
  const user = store.get('user')

  return user && user.isShopManager
}

export function findInObject(obj, id) {
  const index = findIndex(obj, {
    id,
  })
  return index !== -1
}

export function checkIsEditOrder() {
  const query = parseQuery()
  if(query.referrer && query.order_uuid) {
    store.session('diviac.order_uuid', query.order_uuid)
    store.session('diviac.referrer', query.referrer)

    window.location.href = '/checkout' + window.location.pathname
  }
}

export function getTotalGuests(guests) {
  if(!guests) {
    return 0
  }
  return guests.divers + (guests.students || 0) + guests.nonDivers
}

export function setGuestParams(selectedParams, totalGuests) {
  const guestsNumber = totalGuests || selectedParams.totalGuests
  if(guestsNumber) {
    try {
      const guestSplit = selectedParams.guests_split.map((item) =>
        JSON.parse(item),
      )
      // Update localstorage each time when user clicked on item
      const roomParams = {
        id: 'auto',
        rooms: selectedParams.rooms,
        divers: selectedParams.divers,
        nonDivers: selectedParams.nonDivers,
        students: selectedParams.students,
        totalGuests: guestsNumber,
        guests_split: guestSplit,
        details: guestSplit,
      }
      store('roomParams', roomParams)
    } catch (e) {
      console.warn(e)
    }
  }
}

export function setAffiliateCookie() {
  const queryParams = parseQuery() || {}
  const user = store.get('user')
  if(
    (!user || (user && !user.affiliate && !user.isStaff)) &&
    queryParams.aid &&
    queryParams.aid !== 'undefined'
  ) {
    const domain =
      '.' + window.location.hostname.split('.').splice(1).join('.')
    setCookie(
      'padi_aid',
      queryParams.aid,
      {
        domain,
        path: '/',
        expires: 3600 * 24 * 90 /* 90days */,
      },
      Boolean(queryParams.widget),
    )
    if(window.dataLayer) {
      let gtmObject = {
        affiliate_id: queryParams.aid,
        affiliate_type: 'Shop affiliate',
        user_type: 'Affiliate',
      }
      if(queryParams.cid) {
        gtmObject.affiliate_campaign = `${queryParams.cid}_${
          queryParams.campaign_title || 'No title'
        }`
        setCookie(
          'padi_cid',
          queryParams.cid,
          {
            domain,
            path: '/',
            expires: 3600 * 24 * 90 /* 90days */,
          },
          Boolean(queryParams.widget),
        )
      }
      window.dataLayer.push(gtmObject)
    }
  }
}

export function setAdventuresAffiliateCookie() {
  const queryParams = parseQuery()

  if(!isLoggedIn() && queryParams.aid_adventures) {
    setCookie('padi_aid_adventures', queryParams.aid_adventures, {
      domain: '.' + window.location.hostname.split('.').splice(1).join('.'),
      path: '/',
      expires: 3600 * 24 * 90 /* 90days */,
    })
  } else if(queryParams.aid_adventures) {
    linkShopToAccount(queryParams.aid_adventures)
  }
}

export function linkShopToAccount(aid) {
  const user = store.get('user')
  const linkedShop = get(user, 'travelSettings.linkedDiveCenter')
  if(user && !user.isAffiliate && !user.isStaff && !linkedShop) {
    API('account/linked-dive-center/').put({
      affiliate: aid,
    })
  }
}

export function selectAffiliate(id) {
  const user = store.get('user')

  user.affiliate = find(user.affiliates, { memberNumber: id })

  if(user.affiliate) {
    store.set('user', user)
    window.location.reload()
  } else {
    console.warn('no affiliate in list')
  }
}

export function generateAffiliateUrl() {
  let query = '?'
  const user = store.get('user') || {}
  const affiliateIdText = document.getElementById('current-affiliate-id')
  const affiliateQueries = [
    'aid',
    'utm_campaign',
    'utm_source',
    'utm_medium',
    'utm_content',
  ]

  if(user.affiliates && !user.affiliate) {
    user.affiliate = user.affiliates[0]
  }

  if(!user.affiliate) {
    return
  }

  if(affiliateIdText) {
    affiliateIdText.innerText = user.affiliate.memberNumber
  }

  if(window.location.search) {
    const queryParams = parseQuery()
    const cleanedQueryParams = omit(queryParams, function(val, key) {
      return affiliateQueries.indexOf(key) !== -1
    })

    query += serializeData(cleanedQueryParams)
  }

  if(query.length > 1) {
    query += '&'
  }

  query += `aid=${user.affiliate.memberNumber}&utm_campaign=ww-all-all-affiliates-shops&utm_source=affiliate_${user.affiliate.memberNumber}&utm_medium=link&utm_content=direct`

  return window.location.origin + window.location.pathname + query
}

export const generateMonthPicker = (
  numberOfMonthes = 24,
  liveaboardPricingPage,
) => {
  let monthCount = moment().month()
  let time
  if(liveaboardPricingPage) {
    time = [
      {
        id: 'available',
        label: <FormattedMessage {...messages.showAvailable} />,
        from: moment().format('YYYY-MM-DD'),
        to: moment().add('30', 'd').format('YYYY-MM-DD'),
      },
      {
        id: 'all',
        label: <FormattedMessage {...messages.showAll} />,
        from: moment().format('YYYY-MM-DD'),
        to: moment().add('30', 'd').format('YYYY-MM-DD'),
      },
    ]
  } else {
    time = [
      {
        id: '30-days',
        label: <FormattedMessage {...messages.next30} />,
        from: moment().format('YYYY-MM-DD'),
        to: moment().add('30', 'd').format('YYYY-MM-DD'),
      },
    ]
  }
  while(monthCount < numberOfMonthes + moment().month() + 1) {
    time.push({
      id: moment().month(monthCount).locale('en').format('MMM-YYYY'),
      from: moment()
        .month(monthCount)
        .subtract(0, 'months')
        .startOf('month')
        .format('YYYY-MM-DD'),
      to: moment()
        .month(monthCount)
        .subtract(0, 'months')
        .endOf('month')
        .format('YYYY-MM-DD'),
      label: moment()
        .month(monthCount)
        .locale(APP_LANGUAGE)
        .format('MMMM YYYY'),
    })

    monthCount++
  }

  return time
}

export function compareObjects() {
  var i, l, leftChain, rightChain

  function compare2Objects(x, y) {
    var p

    // remember that NaN === NaN returns false
    // and isNaN(undefined) returns true
    if(
      isNaN(x) &&
      isNaN(y) &&
      typeof x === 'number' &&
      typeof y === 'number'
    ) {
      return true
    }

    // Compare primitives and functions.
    // Check if both arguments link to the same object.
    // Especially useful on the step where we compare prototypes
    if(x === y) {
      return true
    }

    // Works in case when functions are created in constructor.
    // Comparing dates is a common scenario. Another built-ins?
    // We can even handle functions passed across iframes
    if(
      (typeof x === 'function' && typeof y === 'function') ||
      (x instanceof Date && y instanceof Date) ||
      (x instanceof RegExp && y instanceof RegExp) ||
      (x instanceof String && y instanceof String) ||
      (x instanceof Number && y instanceof Number)
    ) {
      return x.toString() === y.toString()
    }

    // At last checking prototypes as good as we can
    if(!(x instanceof Object && y instanceof Object)) {
      return false
    }

    if(x.isPrototypeOf(y) || y.isPrototypeOf(x)) {
      return false
    }

    if(x.constructor !== y.constructor) {
      return false
    }

    if(x.prototype !== y.prototype) {
      return false
    }

    // Check for infinitive linking loops
    if(leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) {
      return false
    }

    // Quick checking of one object being a subset of another.
    // todo: cache the structure of arguments[0] for performance
    for(p in y) {
      if(y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
        return false
      } else if(typeof y[p] !== typeof x[p]) {
        return false
      }
    }

    for(p in x) {
      if(y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
        return false
      } else if(typeof y[p] !== typeof x[p]) {
        return false
      }

      switch (typeof x[p]) {
        case 'object':
        case 'function':
          leftChain.push(x)
          rightChain.push(y)

          if(!compare2Objects(x[p], y[p])) {
            return false
          }

          leftChain.pop()
          rightChain.pop()
          break

        default:
          if(x[p] !== y[p]) {
            return false
          }
          break
      }
    }

    return true
  }

  if(arguments.length < 1) {
    return true // Die silently? Don't know how to handle such case, please help...
    // throw "Need two or more arguments to compare";
  }

  for(i = 1, l = arguments.length; i < l; i++) {
    leftChain = [] // Todo: this can be cached
    rightChain = []

    if(!compare2Objects(arguments[0], arguments[i])) {
      return false
    }
  }

  return true
}

// check's if trip start date is earlier than N days from today
export function isLastMinuteBooking(date) {
  let days = 21
  let dateNow = moment()
  let tripStartDate = moment(date)

  return tripStartDate.diff(dateNow, 'days') + 1 <= days
}

export function createURL(path, params, query) {
  let url = path

  if(params) {
    each(params, function(val, key) {
      url = url.replace(':' + key, val)
    })
  }

  if(query) {
    url += query
  }

  return url
}

export function makeCounter() {
  let privateCounter = 0
  function changeBy(val) {
    privateCounter += val
  }
  function setValue(val) {
    privateCounter = val
  }
  return {
    increment: function() {
      changeBy(1)
    },
    decrement: function() {
      changeBy(-1)
    },
    setValue,
    value: function() {
      return privateCounter
    },
  }
}

/** @function scrollScrollToError
 * Basic function that can be used as callback on error
 * .error-field - class that is used for FinalForm`s error fields
 */
export const scrollToFormError = () => {
  const el = document.querySelector('.error-field')
  if(el) {
    el.scrollIntoView({ behavior: 'smooth' })
  }
}

export const scrollToRef = (ref) => {
  const refElement = ref.current
  refElement.scrollIntoView({ behavior: 'smooth' })
  // window.scrollTo(0, ref.current.offsetTop)
}

export function copyTextToClipboard(text, isDiveGuides) {
  const textArea = document.createElement('textarea')
  textArea.value = text
  textArea.style.opacity = 0
  textArea.style.position = 'absolute'
  textArea.style.top = '-999px'
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    document.execCommand('copy')
    toast.success(
      <FormattedMessage
        id='link_copied'
        defaultMessage='Link was copied to your clipboard'
      />,
      {
        position: 'top-center',
        className: isDiveGuides && 'toast-dive-guides', // for overwriting styles
        autoClose: 4000,
      },
    )
  } catch (err) {}

  document.body.removeChild(textArea)
}

/**
 * @function cleanedQueryParams
 * @param  {String} queryName
 * @param  {String} url=document.location.search
 * Polyfill for deleting passed queryparams from history without reloading page
 */

export function cleanQueryParameter(queryName, url = document.location.search) {
  let query

  if(typeof URLSearchParams !== 'undefined') {
    const params = new URLSearchParams(url)
    params.delete(queryName)
    query = params.toString()
  } else {
    // for IE;
    const queryObj = parseQuery(url)
    delete queryObj[queryName]
    query = serializeData(queryObj)
  }

  const path = query.length ? `?${query}` : window.location.pathname
  window.history.replaceState(null, null, path)
}

export function navigateNotTranslated(fromLanguge) {
  // works with not translated urls
  const pathWithoutLanguage = window.location.pathname.replace(
    `${fromLanguge}/`,
    '',
  )
  let query
  if(window.location.search.length) {
    query = window.location.search + `&from_lang=${fromLanguge}`
  } else {
    query = `?from_lang=${fromLanguge}`
  }

  window.location.href = window.location.origin + pathWithoutLanguage + query
}

/**
 * @function getCalendarLocale
 * @retuns  { Object }
 * Function that returns locale as in date-fns npm package
 */

export function getCalendarLocale() {
  const lang = APP_LANGUAGE === 'ach' ? 'en' : APP_LANGUAGE
  const months = moment.localeData(lang).months()

  return {
    localize: {
      month: (n) => months[n],
      day: (n) => n,
    },
    formatLong: {},
  }
}

export function isElementInViewport(el) {
  let top = el.offsetTop
  let left = el.offsetLeft
  let width = el.offsetWidth
  let height = el.offsetHeight

  while(el.offsetParent) {
    el = el.offsetParent
    top += el.offsetTop
    left += el.offsetLeft
  }

  return (
    top >= window.pageYOffset &&
    left >= window.pageXOffset &&
    top + height <= window.pageYOffset + window.innerHeight &&
    left + width <= window.pageXOffset + window.innerWidth
  )
}

export function connectCaptcha(elementId, countryCode, callback) {
  // callback is optional to save captcha id in component state
  if(!document.getElementById(elementId)) {
    return null
  }
  if(document.getElementById('captcha-scripts')) {
    renderCaptcha(elementId, callback)
    return
  }
  const head = document.getElementsByTagName('head')[0]
  const script = document.createElement('script')
  script.id = 'captcha-scripts'
  if(isMainLand(countryCode)) {
    script.src = 'https://recaptcha.google.cn/recaptcha/api.js?render=explicit'
  } else {
    script.src = 'https://www.google.com/recaptcha/api.js?render=explicit'
  }
  head.appendChild(script)
  script.onload = renderCaptcha(elementId, callback)
}

function renderCaptcha(elementId, callback) {
  if(!document.getElementById(elementId)) {
    return null
  }
  if(!window.grecaptcha) {
    setTimeout(() => {
      renderCaptcha(elementId)
    }, 100)
    return
  }
  window.grecaptcha.ready(function() {
    const captchaId = window.grecaptcha.render(elementId, {
      sitekey: process.env.PADI_CAPTCHA_KEY,
      theme: 'light',
    })
    if(typeof callback === 'function') {
      callback(captchaId)
    }
  })
}

export function makeKey(captcha) {
  return captcha + '$' + getCookie('_dds')
}

export function removeHtml(text) {
  return text
    .replace(/<h1>/g, '')
    .replace(/<h2>/g, '')
    .replace(/<h3>/g, '')
    .replace(/<h4>/g, '')
    .replace(/<li>/g, '')
    .replace(/<strong>/g, '')
    .replace(/<ul>/g, '')
    .replace(/<p>/g, '')
    .replace(/<\/li>/g, '\n')
    .replace(/<\/strong>/g, '')
    .replace(/<\/ul>/g, '')
    .replace(/<\/p>/g, '\n')
    .replace(/<\/h1>/g, '\n')
    .replace(/<\/h2>/g, '\n')
    .replace(/<\/h3>/g, '\n')
    .replace(/<\/h4>/g, '\n')
}

/**
 * @function getOccupancyString
 * @param  {Number} occupancy
 * @returns {import('react').ReactComponentElement} FormattedMessage
 */

export function getOccupancyString(occupancy, isSingleOccupancy) {
  const occupancies = {
    1: isSingleOccupancy ? (
      <FormattedMessage
        id='single_occupancy'
        defaultMessage='Single occupancy'
      />
    ) : (
      <FormattedMessage defaultMessage='Shared occupancy' />
    ),
    2: (
      <FormattedMessage
        id='double_occupancy'
        defaultMessage='Double occupancy'
      />
    ),
    3: (
      <FormattedMessage
        id='tripple_occupancy'
        defaultMessage='Tripple occupancy'
      />
    ),
    other: (
      <FormattedMessage
        id='plural_occupancy'
        values={{
          qty: occupancy,
        }}
        defaultMessage='{qty, plural, =0 { No guests } other {# x occupancy }}'
      />
    ),
  }

  return occupancies[occupancy] || occupancies.other
}

export function getOccupancyInRoomString(occupancy, roomsQty) {
  const occupancies = {
    1: (
      <FormattedMessage
        id='single_occupancy_in_room'
        values={{ qty: roomsQty }}
        defaultMessage='{ qty, plural, one { Single occupancy in room } other { Single occupancy in rooms }}'
      />
    ),
    2: (
      <FormattedMessage
        id='double_occupancy_in_room'
        values={{ qty: roomsQty }}
        defaultMessage='{ qty, plural, one { Double occupancy in room } other { Double occupancy in rooms }}'
      />
    ),
    3: (
      <FormattedMessage
        id='tripple_occupancy_in_room'
        values={{ qty: roomsQty }}
        defaultMessage='{ qty, plural, one { Tripple occupancy in room } other { Tripple occupancy in rooms }}'
      />
    ),
    other: (
      <FormattedMessage
        id='plural_occupancy_in_room'
        values={{
          qty: roomsQty,
          occupancy,
        }}
        defaultMessage='{qty, plural, =0 { No guests } one {{occupancy} x occupancy in room} other {{occupancy} x occupancy in rooms}}'
      />
    ),
  }

  return occupancies[occupancy] || occupancies.other
}

export function triggerDatepicker() {
  const picker = document.getElementById('datepicker')
  if(picker) {
    picker.click()
  }
}

export function getCoordinates(successHandler, errorHandler) {
  if(!navigator?.geolocation) {
    if(errorHandler) {
      errorHandler()
    }
    return false
  }
  navigator.geolocation.getCurrentPosition(
    (location) => {
      successHandler(location.coords)
    },
    function() {
      if(errorHandler) {
        errorHandler()
      } else {
        toast.error(
          <FormattedMessage
            id='cant_get_location'
            defaultMessage='Unfortunately we can`t receive your coordinates'
          />,
          {
            position: toast.POSITION.TOP_CENTER,
          },
        )
      }
    },
    {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    },
  )
}

export function getVerboseKind(kind) {
  switch (kind) {
    case 0:
      return <FormattedMessage id='dive_center' defaultMessage='Dive center' />
    case 10:
      return <FormattedMessage id='liveaboard' defaultMessage='Liveaboard' />
    case 20:
      return <FormattedMessage id='dive_resort' defaultMessage='Dive resort' />
  }
}

export function roundPrice(amount) {
  return (Math.round(amount * 100) / 100).toFixed(2)
}

export function generateSeoStringForGuests(params) {
  let string = ''

  if(!params) {
    return ''
  }

  if(params.divers) {
    string += `${params.divers} diver(s), `
  }
  if(params.nonDivers) {
    string += `${params.nonDivers} non-diver(s), `
  }
  if(params.students) {
    string += `${params.students} student(s), `
  }

  if(string) {
    string = string.slice(0, -2)
  }

  return string
}

export function getKindVerbose(kind) {
  if(kind === 0) {
    return 'Dive Center'
  } else if(kind === 10) {
    return 'Liveaboard'
  }
  return 'Dive Resort'
}

export function getTravelDomain() {
  if(window.location.hostname.search('travel.') === -1) {
    return process.env.DOMAIN
  }

  return ''
}

export function addParamToUrl(url, param) {
  if(!param || !url) {
    return url
  }
  url += (url.split('?')[1] ? '&' : '?') + param
  return url
}

export function getAffiliateId() {
  const aid = getCookie('padi_aid') || undefined
  const cid = getCookie('padi_cid') || undefined
  const loggedIn = isLoggedIn()
  const user = store.get('user')

  if(loggedIn) {
    if(user?.affiliate) {
      return { aid: user.affiliate.memberNumber }
    }

    if(user?.isStaff) {
      return {}
    }
  }

  if(aid) {
    return {
      aid,
      cid,
    }
  }

  return {}
}

export function getUserData() {
  const loggedIn = isLoggedIn()
  const user = store('user')
  if(loggedIn && user && !user.isStaff) {
    const contactInformation = get(user, 'contactInformation', {})
    return {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phone: contactInformation && contactInformation.phoneMobile,
      gender: user.gender,
    }
  }

  return {}
}

export function createLoginUrl(redirectTo, params, query) {
  const redirectUrl = redirectTo
    ? `${process.env.DOMAIN}${createTranslatedUrl(redirectTo, params, query)}`
    : window.location.href
  const cognitoURL = process.env.SSO_URL
  const options = {
    domain: '.' + window.location.hostname.split('.').splice(1).join('.'),
    path: '/',
    expires: 3600, // 1 hour
  }
  setCookie('login_next_page', redirectUrl, options)

  return cognitoURL + '&redirect_uri=' + process.env.DOMAIN + '/cognito_auth'
}

export const isAutosuggestInQuery = () =>
  Boolean(parseQuery()['shop_title_autosuggest'])

export const convertToArray = (singleElement) =>
  Array.isArray(singleElement) ? singleElement : [singleElement]

export const isWechat = () => window.navigator.userAgent.toLocaleLowerCase().indexOf('micromessenger') > 0

export const getWeChatAuthUrl = (referer) => {
  const state = 'padidiving'
  const WECHAT_AUTH_CALLBACK = 'https://wx.padi.com.cn/redirected_uri'
  const WECHAT_WEB_APP_ID = 'wxb6e61a33874ff326'
  const refererObj = JSON.stringify(referer)
  const authCallbackUrl = `${WECHAT_AUTH_CALLBACK}?referer=${encodeURIComponent(refererObj)}`
  return `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${WECHAT_WEB_APP_ID}&redirect_uri=${encodeURIComponent(authCallbackUrl)}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`
}

export const CN_CODE = 'CN'
